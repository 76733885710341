<template>
    <div class="content">
        <div class="row">

            <div class="col-md-12">
                <vuestic-widget >
                    <div class="row">
                        <div class="col-md-3">
                            <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.station.back' | translate}}</button>
                        </div>
                          <div class="col-md-3">
                          <!--  <button type="button" v-on:click="addProducts" class="btn btn-danger btn-sm">{{'view.station.addproduct' | translate}}</button>
                      -->  </div>
                        <div class="col-md-3">
                          <!--  <button type="button" v-on:click="addProducts" class="btn btn-info btn-sm">{{'view.station.addproduct' | translate}}</button>
                      -->  </div>
                        <div class="col-md-3" v-show="statusEvent === 1">
                            <button type="button" v-on:click="trashStation" class="btn btn-warning btn-sm">{{'view.station.delete' | translate}}</button>
                        </div>

                    </div>

                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <vuestic-widget :headerText="$t('view.station.update')">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                                <div class="input-group">
                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                    <label class="control-label" for="name">{{'view.station.fields.name' | translate}} </label><i class="bar"></i>
                                    <small v-show="errors.has('name')" class="help text-danger">
                                        {{ errors.first('name') }}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('location'), 'valid': isSuccessfulLocationValid}">
                                <div class="input-group">
                                    <input id="location" name="location" v-model="location" />
                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                    <label class="control-label" for="location">{{'view.station.fields.location' | translate}} </label><i class="bar"></i>
                                    <small v-show="errors.has('location')" class="help text-danger">
                                        {{ errors.first('location') }}
                                    </small>
                                </div>
                            </div>

                             <div class="form-group" >
                                <div class="input-group">
                                    <input type='number' id="order" name="order" v-model="order" />
                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                    <label class="control-label" for="order">{{'view.station.fields.order' | translate}} </label><i class="bar"></i>

                                </div>
                            </div>


                        </div>
                        <div class="col-md-6">

                           <!-- <vuestic-simple-select :label="'view.station.fields.typestation' | translate" v-model="idtypestation" option-key="name" v-bind:options="typestations"></vuestic-simple-select>
                            -->

              <br>
              <multiselect
                v-model="typestation"
                deselect-label="Selected Element"
                track-by="name"
                group-values="stations"
                group-label="name"
                :group-select="false"
                label="name"
                placeholder="Tipo de Estación"
                :options="typestationlist"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  Tipo de Estación:
                  <label style="font-weight:600">{{ option.name }}</label>
                </template>
              </multiselect>
               <br>
              <multiselect
                v-model="ubication"
                deselect-label="Selected Element"
                track-by="name"
                label="name"
                placeholder="Ubicación"
                :options="ubicationlist"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  Ubicación de Estación:
                  <label style="font-weight:600">{{ option.name }}</label>
                </template>
              </multiselect>
                <br>
              <multiselect
                v-model="day"
                deselect-label="Selected Element"
                track-by="name"
                label="name"
                placeholder="Día"
                :options="daylist"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  Día en el Evento:
                  <label style="font-weight:600">{{ option.name }}</label>
                </template>
              </multiselect>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                                <div class="input-group">
                                    <vuestic-switch v-model="active">
                                        <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                        <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                    </vuestic-switch>
                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                    <small v-show="errors.has('active')" class="help text-danger">
                                        {{ errors.first('active') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="statusEvent != 3">
                        <button type="button" v-on:click="editStation" class="btn btn-primary btn-sm">{{'view.station.update' | translate}}</button>
                    </div>

                </vuestic-widget>

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import TableStation from '../tables/TableStation/TableStation.vue'
import Multiselect from 'vue-multiselect'
import {
  SpringSpinner
} from 'epic-spinners'
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name: 'update-station',
  components: {
    SpringSpinner,
    TableStation,
    Multiselect
  },
  mounted () {
    var id = this.$route.params.id
    if (id !== null) {
      this.loadStation(id)
        .then(data => {
          this.name = data.name;
          this.location = data.location;
          this.typestation = data.type_station;
          this.ubication = data.ubication;
          this.day = data.day_event;
          this.order = data.order;

          this.active = data.active;
        })
        .catch(data => {
          this.addToastMessage({
            text: 'Ocurrio un error',
            type: 'success'
          })
        })
    } else {
      this.addToastMessage({
        text: 'Ocurrio un error',
        type: 'success'
      })
    }
    this.loadTypestationsActive()
      .then(data => {
        // this.typestationlist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })


     this.loadUbicationEventByEvent(this.idevent)
      .then(data => {
        this.ubicationlist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })

       this.loadDayEventByEvent(this.idevent)
      .then(data => {
        this.daylist = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })
  },
  computed: {
    ...mapState({
      typestationlist: state => state.typestation.typestations,
      categorystationoptions: state => state.typestation.categorystations,
      events: state => state.event.events,
      idevent: state => state.event.event.id,
      statusEvent: state => state.event.event.status,

    }),
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulNumberValid () {
      let isValid = false
      if (this.formFields.number) {
        isValid = this.formFields.number.validated && this.formFields.number.valid
      }
      return isValid
    },
    isSuccessfulLocationValid () {
      let isValid = false
      if (this.formFields.description) {
        isValid =
                        this.formFields.location.validated && this.formFields.location.valid
      }
      return isValid
    }
  },
  data () {
    return {
      header: 'Actualizar Estación',
      typestation: null,
      categorystation: [],
      ubicationlist:[],
      daylist:[],
      //  typestationlist: [],
      ubication:'',
      day:'',
      name: '',
      number: '',
      location: '',
      order:'',
      idtypestation: '',
      eventoptions: null,
      active: 1
    }
  },

  methods: {
    ...mapActions([
      'loadTypestationsActive',
      'loadCategorystations',
      'loadEvents',
      'updateStation',
      'deleteStation',
      'loadStation',
      'addToastMessage',
      'loadUbicationEventByEvent',
      'loadDayEventByEvent'
    ]),
    back () {
      this.$router.go(-1)
    },
    addProducts () {
      var id = this.$route.params.id
      this.$router.replace('../' + id + '/product')
    },
    editStation () {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id;

          this.updateStation({
            name: this.name,
            location: this.location,
            id_event: this.idevent,
            id_type_station: this.typestation.id,
            id_ubication:this.ubication.id,
            id_day_event:this.day.id,
            order:this.order,
            active: this.active,
            id: id
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() => this.$router.replace('/station'), 2000)
            })
            .catch(data => {
              this.error = data.message
              this.errors = data.errors || {}
            })
          // this.$refs.vuetable.refresh();
        }
      })
    },
    trashStation () {
      var id = this.$route.params.id
    //  console.log(id)
      this.deleteStation({
        id: id
      })
        .then((data) => {
          this.addToastMessage({
            text: data.message,
            type: 'warning'
          })
          setTimeout(() => this.$router.go(-1), 100)
        })
        .catch(data => {
          this.error = data.message
          this.errors = data.errors || {}
        })
      // this.$refs.vuetable.refresh();
    },
    cleanform () {
      this.name = null
      this.description = null
    }
  }
}
</script>

<style lang="scss">
    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
